import * as React from "react"
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import Grid from '@material-ui/core/Grid';
import Layout from "../components/layout"
import Seo from "../components/seo"

const MOBILE_BREAKPOINT = 721;
const isClient = typeof window !== 'undefined';
class ProfilePage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) {
    super(props)
    this.state = {
      viewportWidth: isClient ? window.innerWidth : 0,     
    }
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth });
  }
  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }      
  }
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')   
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = "My EdgeForecaster"
    const {viewportWidth,} = this.state;    
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT);
    
    var pagewidth = { matches: false, media: "(max-width: 600px)", onchange: null}    
    if (typeof window !== "undefined") { pagewidth = window.matchMedia('(max-width: 600px)');}


    var profilepage = (
      <Grid container spacing={0}>
        <Grid item xs={1} />
        <Grid item xs={8}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <div className="profilecontainer">
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <div className="profilecontainerheading">
                          Profile Details
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={0}>
                          <Grid item xs={4}>
                            <div className="profilecontainersubheading">
                              Name:
                            </div>
                          </Grid>                          
                          <Grid item xs={8}>
                            <div className="profilecontainertext">
                              {/* {this.props.allCookies.userdetails.firstName +" "+this.props.allCookies.userdetails.lastName} */}
                            </div>                            
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={0}>
                          <Grid item xs={4}>
                            <div className="profilecontainersubheading">
                              Email:
                            </div>
                          </Grid>                          
                          <Grid item xs={8}>
                            <div className="profilecontainertext">
                              {/* {this.props.allCookies.userdetails.email} */}
                            </div>                            
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={0}>
                          <Grid item xs={4}>
                            <div className="profilecontainersubheading">
                              Location:
                            </div>
                          </Grid>                          
                          <Grid item xs={8}>
                            <div className="profilecontainertext">
                              (update)
                            </div>                            
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={0}>
                          <Grid item xs={4}>
                            <div className="profilecontainersubheading">
                              Newsletter:
                            </div>
                          </Grid>                          
                          <Grid item xs={8}>
                            <div className="profilecontainertext">
                              {/* {this.props.allCookies.newsletter} */}
                            </div>                            
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={0}>
                          <Grid item xs={4}>
                            <div className="profilecontainersubheading">
                              Promo Code:
                            </div>
                          </Grid>                          
                          <Grid item xs={8}>
                            <div className="profilecontainertext">
                              (update)
                            </div>                            
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={0}>
                          <Grid item xs={4}>
                            <div className="profilecontainersubheading">
                              Socials:
                            </div>
                          </Grid>                          
                          <Grid item xs={8}>
                            <div className="profilecontainertext">
                              (update)
                            </div>                            
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={5}>
                  <div className="profilecontainer">
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <div className="profilecontainerheading">
                          Subscription
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="profilecontainer">
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <div className="profilecontainerheading">
                      Actions
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={2}/>
                  <Grid item xs={8}>
                    <div >
                      <button type="submit" className="profileaction_button">Edit Details</button>
                    </div>
                  </Grid>
                  <Grid item xs={2}/>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={2}/>
                  <Grid item xs={8}>
                    <div >
                      <button type="submit" className="profileaction_button">Change Password</button>
                    </div>
                  </Grid>
                  <Grid item xs={2}/>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={2}/>
                  <Grid item xs={8}>
                    <div >
                      <button type="submit" className="profileaction_button">Change Subscription</button>
                    </div>
                  </Grid>
                  <Grid item xs={2}/>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={2}/>
                  <Grid item xs={8}>
                    <div >
                      <button type="submit" className="profileaction_button_main">Launch EdgeForecaster</button>
                    </div>
                  </Grid>
                  <Grid item xs={2}/>
                </Grid>                
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

    return (
      <React.Fragment>
        <Layout data={this.props} pagewidth={pagewidth.matches}>
        <Seo pagename={pageName +" "+ siteTitle}  keywords={keywords}/>          
          <div className="page_wrapper generalpage_wrapper">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h1 className="generalpage_majorheading">{pageName}</h1>
              </Grid>
            </Grid>
            {pagewidth.matches ? profilepage : <div />}
          </div>
        </Layout>
      </React.Fragment>
      
    )}
}

export default withCookies(ProfilePage)
export const ProfilePageQuery = graphql`
  query ProfilePageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl              
        }
        keywords
      }
    }
    sitePage {
        pluginCreator {
          name
        }
      }
  }
`